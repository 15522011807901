<template>
  <div class="resourceContent">
      <div class="pxkeji-right-box">
        <a-form class="ant-advanced-search-form">
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-item label="姓名">
                <a-input placeholder="请输入" v-model="search.name" />
              </a-form-item>
            </a-col>
            <a-col :span="4" :style="{ textAlign: 'left' }">
              <a-form-item>
                <a-button type="primary" @click="searchList">搜索</a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <div>
          <pxkeji-btn
            name="删除"
            :disabled="selectRows.length == 0"
            @click="delInfos()"
            :roles="[]"
            class="pxkeji_basic_btn"
          />
        </div>
      </div>
      <div>
        <a-table
          rowKey="id"
          size="small"
          bordered
          :columns="list.columns"
          :pagination="list.pagination"
          @change="onPageChange"
          :dataSource="list.datas"
          :loading="isloading"
          :rowSelection="{
            type: 'checkbox',
            onChange: onRowChange,
            columnWidth: 40,
            selectedRowKeys: selectRows,
          }"
          :locale="{ emptyText: '暂无数据' }"
          :scroll="{ y: list.tableHeight }"
        >
          <span slot="action" slot-scope="action">
            <pxkeji-btn
              name="查看"
              @click="showInfo(action.id)"
              size="small"
              type="primary"
              class="pxkeji_dro_btn"
              :roles="[]"
            />
            <pxkeji-btn
              name="生成证明"
              @click="toCreateProve(action.id)"
              size="small"
              type="primary"
              class="pxkeji_dro_btn"
              :roles="[]"
              v-if="!action.recordId"
            />
            <a-popconfirm @confirm="delInfo(action.id)">
              <div slot="title">是否确认删除此就业记录？</div>
              <a-icon slot="icon" type="question-circle-o" />
              <pxkeji-btn
                name="删除"
                size="small"
                type="danger"
                class="pxkeji_dro_btn"
                :roles="[]"
              />
            </a-popconfirm>
          </span>
        </a-table>
      </div>
    <a-modal
      :title="info.title"
      v-model="info.visible"
      width="1000px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <RecruitPracticeInfo @callbackMethod="save" :infos="info" />
    </a-modal>
    <a-modal
      title="选择证书"
      v-model="selCert.visible"
      width="800px"
      destroyOnClose
      :maskClosable="false"
      @ok="handleCertOk"
      @cancel="handleCertCancel"
    >
      <a-table
        rowKey="id"
        size="small"
        bordered
        :columns="certList.columns"
        :pagination="false"
        :dataSource="certList.datas"
        :rowSelection="{
          type: 'radio',
          onChange: onCertRowChange,
          columnWidth: 40,
          selectedRowKeys: selectCertRows,
        }"
        :locale="{ emptyText: '暂无数据' }"
      >
        <template slot="validity" slot-scope="validity">
          {{ validity == 0 ? "永久" : validity + "年" }}
        </template>
      </a-table>
    </a-modal>
  </div>
</template>
<script>
import RecruitPracticeInfo from "./recruitPracticeInfo.vue"; //编辑

const columns = [
  {
    dataIndex: "name",
    align: "left",
    title: "姓名",
    ellipsis: true,
  },
  {
    dataIndex: "idCard",
    align: "center",
    title: "身份证号",
    ellipsis: true,
  },
  {
    dataIndex: "graduation",
    align: "center",
    title: "毕业院校",
    ellipsis: true,
  },
  {
    dataIndex: "speciality",
    align: "center",
    title: "专业",
    ellipsis: true,
  },
  {
    dataIndex: "company",
    key: "company",
    align: "center",
    title: "实习单位",
    ellipsis: true,
  },
  {
    dataIndex: "arrivalTime",
    key: "arrivalTime",
    align: "center",
    title: "到岗时间",
    width: 160,
  },
  {
    dataIndex: "internshipPeriod",
    key: "internshipPeriod",
    align: "center",
    title: "实习期",
    ellipsis: true,
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    width: 240,
    scopedSlots: { customRender: "action" },
  },
];

let _this;
export default {
  name: "RecruitPracticeList",
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 293,
      },
      search: {
        status: "",
        name: "",
      },
      selectRows: [], //选中项编号
      isloading: false,
      info: {
        title: "",
        visible: false,
        id: 0,
      },
      certList: {
        columns: [
          {
            dataIndex: "name",
            align: "left",
            title: "证书名称",
            ellipsis: true,
          },
          {
            dataIndex: "validity",
            align: "center",
            title: "有效期",
            width: 120,
            scopedSlots: { customRender: "validity" },
          },
        ],
        datas: [],
      },
      selectCertRows: [],
      selCert: {
        visible: false,
        currPracticeId: null,
        currCertificateId: null,
      },
    };
  },
  components: {
    RecruitPracticeInfo,
  },
  mounted() {
    _this = this;
    if (window.innerWidth >= 1600) {
      _this.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 292;
      _this.innerWidth = window.innerWidth;
      if (window.innerWidth >= 1600) {
        _this.list.pagination.pageSize = 20;
      } else {
        _this.list.pagination.pageSize = 10;
      }
    };
    _this.GetList();
  },
  methods: {
    GetList() {
      //获取列表
      _this.isloading = true;
      _this.clearRows();
      _this.$api.recruit
        .getPracticePagedList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          _this.search.name
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    onRowChange(selectedRowKeys) {
      _this.selectRows = selectedRowKeys;
    },
    clearRows() {
      //清空选中项
      _this.selectRows = [];
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    showInfo(id) {
      _this.info.title = id == 0 ? "添加" : "查看";
      _this.info.id = id;
      _this.info.visible = true;
    },
    /**删除 */
    delInfo(ids) {
      _this.isloading = true;
      _this.$api.recruit
        .deleteRecruitPractice(ids)
        .then((res) => {
          _this.isloading = false;
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    delInfos() {
      _this.delInfo(_this.selectRows.join(","));
    },
    toCreateProve(id) {
      _this.selCert.currPracticeId = id;
      _this.$api.certificate.getCertificatesByType(1208).then((res) => {
        if (res.errorCode == _this.$msg.responseCode.success) {
          _this.certList.datas = res.data;
        }
      });
      _this.selCert.visible = true;
    },
    onCertRowChange(selectedRowKeys) {
      _this.selectCertRows = selectedRowKeys;
      _this.selCert.currCertificateId = selectedRowKeys[0];
    },
    handleCertOk() {
      _this.$api.recruit
        .createProve(
          _this.selCert.currPracticeId,
          _this.selCert.currCertificateId
        )
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
          }
        });
      _this.selCert.visible = false;
    },
    handleCertCancel() {
      _this.selCert.currPracticeId = null;
      _this.selCert.currCertificateId = null;
      _this.selectCertRows = [];
    },
    //子组件传值
    save() {
      _this.info.visible = false;
      _this.resourceData.visible = false;
      _this.GetList();
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.resourceContent:after {
  content: "";
  height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}
.articleMeauleft {
  width: 20%;
  padding: 0 1%;
  min-height: 100px;
  background: #fff;
  float: left;
  margin-top: 10px;
}
</style>

