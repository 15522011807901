var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resourceContent"},[_c('div',{staticClass:"pxkeji-right-box"},[_c('a-form',{staticClass:"ant-advanced-search-form"},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":6}},[_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.search.name),callback:function ($$v) {_vm.$set(_vm.search, "name", $$v)},expression:"search.name"}})],1)],1),_c('a-col',{style:({ textAlign: 'left' }),attrs:{"span":4}},[_c('a-form-item',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.searchList}},[_vm._v("搜索")])],1)],1)],1)],1),_c('div',[_c('pxkeji-btn',{staticClass:"pxkeji_basic_btn",attrs:{"name":"删除","disabled":_vm.selectRows.length == 0,"roles":[]},on:{"click":function($event){return _vm.delInfos()}}})],1)],1),_c('div',[_c('a-table',{attrs:{"rowKey":"id","size":"small","bordered":"","columns":_vm.list.columns,"pagination":_vm.list.pagination,"dataSource":_vm.list.datas,"loading":_vm.isloading,"rowSelection":{
          type: 'checkbox',
          onChange: _vm.onRowChange,
          columnWidth: 40,
          selectedRowKeys: _vm.selectRows,
        },"locale":{ emptyText: '暂无数据' },"scroll":{ y: _vm.list.tableHeight }},on:{"change":_vm.onPageChange},scopedSlots:_vm._u([{key:"action",fn:function(action){return _c('span',{},[_c('pxkeji-btn',{staticClass:"pxkeji_dro_btn",attrs:{"name":"查看","size":"small","type":"primary","roles":[]},on:{"click":function($event){return _vm.showInfo(action.id)}}}),(!action.recordId)?_c('pxkeji-btn',{staticClass:"pxkeji_dro_btn",attrs:{"name":"生成证明","size":"small","type":"primary","roles":[]},on:{"click":function($event){return _vm.toCreateProve(action.id)}}}):_vm._e(),_c('a-popconfirm',{on:{"confirm":function($event){return _vm.delInfo(action.id)}}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v("是否确认删除此就业记录？")]),_c('a-icon',{attrs:{"slot":"icon","type":"question-circle-o"},slot:"icon"}),_c('pxkeji-btn',{staticClass:"pxkeji_dro_btn",attrs:{"name":"删除","size":"small","type":"danger","roles":[]}})],1)],1)}}])})],1),_c('a-modal',{attrs:{"title":_vm.info.title,"width":"1000px","footer":false,"destroyOnClose":"","maskClosable":false},model:{value:(_vm.info.visible),callback:function ($$v) {_vm.$set(_vm.info, "visible", $$v)},expression:"info.visible"}},[_c('RecruitPracticeInfo',{attrs:{"infos":_vm.info},on:{"callbackMethod":_vm.save}})],1),_c('a-modal',{attrs:{"title":"选择证书","width":"800px","destroyOnClose":"","maskClosable":false},on:{"ok":_vm.handleCertOk,"cancel":_vm.handleCertCancel},model:{value:(_vm.selCert.visible),callback:function ($$v) {_vm.$set(_vm.selCert, "visible", $$v)},expression:"selCert.visible"}},[_c('a-table',{attrs:{"rowKey":"id","size":"small","bordered":"","columns":_vm.certList.columns,"pagination":false,"dataSource":_vm.certList.datas,"rowSelection":{
        type: 'radio',
        onChange: _vm.onCertRowChange,
        columnWidth: 40,
        selectedRowKeys: _vm.selectCertRows,
      },"locale":{ emptyText: '暂无数据' }},scopedSlots:_vm._u([{key:"validity",fn:function(validity){return [_vm._v(" "+_vm._s(validity == 0 ? "永久" : validity + "年")+" ")]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }